import React, { useEffect, useState } from 'react';
import AppBarWithDrawer from '../../components/organisms/appBarWithDrawer';
import { Footer } from '../../components/organisms/footer';
import { Container, Content } from './styles';

export const TermsOfService = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <AppBarWithDrawer
        handleMenuClick={() => setDrawerOpen(true)}
        handleCloseMenu={() => setDrawerOpen(false)}
        openDrawer={drawerOpen}
        appBarPosition='fixed'
      />

      <Content>
        <h1>Termos de uso</h1>

        <p>
          Bem-vindo à Umatch. Estes <strong>Termos de Uso</strong> regem a relação entre você e UMATCH LTDA, empresa
          registrada sob CPNJ número 38.822.591/0001-55. Entendemos que você nos confia as suas informações e zelamos
          por essa confiança. Por isso, neste documento, explicamos as informações que coletamos, o motivo pela qual as
          coletamos, como são usadas e os seus direitos em relação às suas informações. <br />
          <br />
          <strong>Atualização: </strong>30/11/2021
        </p>

        <h2>1. Qualificação</h2>
        <p>
          Você deve ter, no mínimo, 18 anos de idade para criar uma conta na Umatch e utilizar o Serviço. Ao criar uma
          conta e utilizar o Serviço, você declara e garante que: você pode firmar um contrato vinculativo com a Umatch,
          não está impedido de utilizar o Serviço nos termos das leis do Brasil, cumprirá este Contrato e todas as leis,
          normas e regulamentos municipais, estaduais, nacionais e internacionais aplicáveis e você nunca foi condenado
          por crime ou ofensa grave (ou qualquer crime de mesma gravidade), crime de assédio sexual, ou qualquer crime
          que envolva violência, e que você não é obrigado a se registrar no cadastro de criminosos sexuais de qualquer
          estado, federação ou local.
        </p>

        <h2>2. Sua Conta</h2>
        <p>
          Para usar a Umatch, você deve realizar cadastro e, através deste, comprovar estar matriculado em uma
          Universidade do Brasil ou ter se graduado dentro do período de 1 (um) ano em uma Universidade do Brasil. Se o
          fizer, você nos autoriza a acessar suas informações. Para saber mais sobre as informações que coletamos de
          você e como as utilizamos, consulte a nossa Política de Privacidade em https://www.umatch.com.br/privacy. Você
          é responsável por manter a confidencialidade de suas credenciais de login que utiliza para se conectar à
          Umatch, além de ser o único responsável por todas as atividades associadas ao uso dessas credenciais. Se você
          acredita que alguém obteve acesso à sua conta, entre em contato conosco imediatamente.
        </p>
        <h2>3. Alterações do Serviço e Rescisão</h2>
        <p>
          A Umatch está empenhada em melhorar o Serviço e a oferecer funcionalidades adicionais que possam ser úteis e
          interessantes para você. Isso significa que podemos incluir novos recursos ou aprimorar produtos ao longo do
          tempo, bem como remover alguns recursos e, se essas ações não afetarem os seus direitos e obrigações
          significativamente, talvez não forneceremos aviso prévio antes de removê-los. Podemos até mesmo suspender
          totalmente o Serviço, caso em que você será notificado com antecedência, a menos que circunstâncias
          atenuantes, como questões de segurança, impeçam-nos de fazê-lo. Você pode encerrar sua conta a qualquer
          momento, por qualquer motivo, seguindo as instruções em “Configurações”, no Serviço; no entanto, se você usar
          uma conta de pagamento de terceiros, você precisará gerenciar suas compras no aplicativo por meio dessa conta
          (por exemplo, iTunes, Google Play) para evitar cobranças adicionais. A Umatch poderá cancelar sua conta a
          qualquer momento, sem aviso prévio, se acreditar que você violou este Contrato. Após o referido cancelamento,
          você não terá direito a nenhum reembolso pelas compras.
        </p>

        <h2>4. Segurança; suas interações com outros membros</h2>
        <p>
          Embora a Umatch se esforce para incentivar uma experiência de membro com respeito, por meio de recursos como a
          dupla aceitação, que permite aos membros se comunicarem apenas se ambos tiverem interesse um pelo outro, não
          somos responsáveis pela conduta do membro dentro ou fora do Serviço. Você concorda em agir com cautela em
          todas as interações com outros membros, especialmente ao decidir se comunicar fora do Serviço ou
          pessoalmente.Você concorda que não fornecerá suas informações financeiras (p. ex., cartão de crédito ou
          informações de conta bancária) e que não transferirá ou enviará dinheiro por outros meios a outros membros.
          VOCÊ É O ÚNICO RESPONSÁVEL POR SUAS INTERAÇÕES COM OUTROS MEMBROS. VOCÊ COMPREENDE QUE A UMATCH NÃO INVESTIGA
          OS ANTECEDENTES CRIMINAIS DOS MEMBROS, NEM VERIFICA O HISTÓRICO DOS MEMBROS. A UMATCH NÃO MANIFESTA
          DECLARAÇÕES OU GARANTIAS EM RELAÇÃO À CONDUTA DOS MEMBROS.
        </p>

        <h2>5. Direitos concedidos a você pela Umatch</h2>
        <p>
          A Umatch concede a você uma licença pessoal, universal, isenta de royalties, intransferível, não exclusiva,
          revogável e não sublicenciável para acessar e utilizar o Serviço. Essa licença é para o propósito exclusivo de
          permitir que você use e desfrute dos benefícios do Serviço, conforme a intenção do Umatch e autorização
          prevista neste Contrato. Portanto, você concorda em não: usar o Serviço, ou qualquer conteúdo contido no
          Serviço, para fins comerciais sem a nossa autorização por escrito; copiar, modificar, transmitir, criar
          trabalhos derivados, fazer uso ou reproduzir em qualquer formato materiais protegidos por direitos autorais,
          imagens, marcas comerciais, nomes comerciais, marcas de serviço ou outros direitos de propriedade intelectual,
          conteúdo ou informações de propriedade exclusiva acessíveis através do Serviço, sem a autorização prévia por
          escrito da Umatch; expressar ou sugerir que suas declarações sejam endossadas pela Umatch; utilizar robô, bot,
          spider, rastreador, scraper, aplicativo de busca/recuperação de site, proxy ou outro dispositivo, método ou
          processo manual ou automático para acessar, recuperar, indexar, realizar “data mine” ou, de outra forma,
          reproduzir ou contornar a estrutura de navegação ou apresentação do Serviço ou conteúdos; utilizar o Serviço
          de forma que possa interferir, interromper ou afetar negativamente o Serviço, os servidores ou redes
          conectadas ao Serviço; enviar vírus ou outro código malicioso ou, de outra forma, comprometer a segurança do
          Serviço; forjar cabeçalhos ou manipular outros identificadores para disfarçar a origem das informações
          transmitidas para ou através do Serviço; “enquadrar” ou “espelhar” qualquer parte do Serviço sem autorização
          prévia por escrito da Umatch; utilizar meta-tags, códigos ou outros dispositivos que contenham referências à
          Umatch ou ao Serviço (ou marca comercial, marca registrada, marca de serviço, logotipo ou slogan da Umatch)
          para direcionar pessoas para outro site, para qualquer fim; modificar, adaptar, sublicenciar, traduzir,
          vender, promover engenharia reversa, decifrar, descompilar ou desmontar qualquer parte do Serviço ou fazer com
          que outras pessoas o façam; utilizar ou desenvolver aplicativos de terceiros que interajam com o Serviço, o
          conteúdo ou informações de outros membros sem a nossa autorização por escrito; utilizar, acessar ou publicar a
          interface de programação do aplicativo Umatch sem a nossa autorização por escrito; verificar, analisar ou
          testar a vulnerabilidade de nosso Serviço ou de qualquer sistema ou rede; incentivar ou promover qualquer
          atividade que viole este Contrato. A Empresa pode investigar e tomar todas as medidas legalmente disponíveis
          em resposta a usos ilegais e/ou não autorizados do Serviço, inclusive o cancelamento da sua conta. Qualquer
          software que fornecemos a você pode automaticamente baixar e instalar atualizações, melhorias ou outros novos
          recursos. Você poderá ajustar downloads automáticos através das configurações do seu dispositivo.
        </p>

        <h2>6. Direitos concedidos à Umatch por você</h2>
        <p>
          Ao criar uma conta, você concede à Umatch uma licença universal, transferível, sublicenciável e isenta de
          royalties e o direito de hospedar, armazenar, utilizar, copiar, exibir, reproduzir, adaptar, editar, publicar,
          modificar e distribuir informações que você nos autoriza a acessar, bem como outras informações que você
          publicar, fizer upload, exibir ou, de outra forma, disponibilizar (coletivamente, “publicar”) no Serviço ou
          transmitir a outros membros (coletivamente, “Conteúdo”). Referente ao seu Conteúdo, a licença da Umatch não
          será exclusiva, considerando que a licença da Umatch será exclusiva com relação aos trabalhos derivados
          criados por meio da utilização do Serviço. Por exemplo, a Umatch pode ter uma licença exclusiva às capturas de
          tela do Serviço que incluam o seu Conteúdo. Além disso, para que a Umatch possa evitar o uso de seu Conteúdo
          fora do Serviço, você autoriza a Umatch a agir em seu nome com relação a usos ilícitos de seu Conteúdo
          retirados do Serviço por outros membros ou terceiros. Isso inclui expressamente a autorização, mas não a
          obrigação, de enviar avisos em seu nome caso o Conteúdo seja retirado e utilizado por terceiros fora do
          Serviço. Nossa licença referente ao seu Conteúdo está sujeita a seus direitos de acordo com a lei aplicável
          (por ex., leis referentes à proteção de dados pessoais, na medida em que qualquer Conteúdo contenha
          informações pessoais, conforme definido por tais leis) e para fins exclusivos de operação, desenvolvimento,
          fornecimento e melhoria do Serviço, bem como pesquisas e desenvolvimentos de novos serviços. Você concorda que
          qualquer Conteúdo que você colocar ou nos autorizar a colocar no Serviço poderá ser visto por outros membros e
          por qualquer outra pessoa que esteja acessando ou participando do Serviço (como pessoas que podem receber
          Conteúdo compartilhado por outros membros da Umatch). Você concorda que todas as informações apresentadas após
          a criação da sua conta, são corretas e verdadeiras, e você tem o direito de publicar o Conteúdo no Serviço e
          conceder a licença acima descrita à Umatch. Você compreende e concorda que podemos monitorar ou revisar
          qualquer Conteúdo que você publicar como parte do Serviço. Podemos excluir qualquer Conteúdo, no todo ou em
          parte, que, a nosso critério exclusivo, viole este Contrato ou possa prejudicar a reputação do Serviço. Ao se
          comunicar com os nossos representantes de atendimento ao cliente, você concorda em agir com respeito e
          gentileza. Se considerarmos que o seu comportamento em relação a qualquer de nossos representantes de
          atendimento ao cliente ou outros funcionários revele-se, em qualquer momento, ameaçador ou ofensivo,
          reservamo-nos o direito de cancelar a sua conta imediatamente. Em consideração à Umatch, a qual permite que
          você utilize o Serviço, você concorda que poderá haver publicidade no Serviço veiculada por nós, nossas
          afiliadas e parceiros terceirizados. Ao enviar sugestões ou comentários à Umatch sobre o nosso Serviço, você
          concorda que a Umatch poderá utilizar e compartilhar as sugestões para qualquer finalidade, sem compensação.
          Você está ciente de que a Umatch poderá acessar, armazenar e divulgar as informações da sua conta e Conteúdo
          se exigido por lei ou se acreditar, de boa-fé, que o acesso, armazenamento ou divulgação satisfaçam um
          interesse legítimo, incluindo: (i) cumprir com um processo judicial; (ii) fazer cumprir o Contrato; (iii)
          responder a reivindicações de qualquer Conteúdo que viole os direitos de terceiros; (iv) responder às suas
          solicitações de atendimento ao cliente; ou (v) proteger os direitos, bens ou segurança pessoal da Empresa ou
          de qualquer outra pessoa.
        </p>

        <h2>7. Regras da comunidade</h2>
        <p>
          Ao utilizar o Serviço, você concorda em não: utilizar o Serviço para qualquer finalidade que seja ilegal ou
          proibida por este Contrato; usar o Serviço para qualquer fim prejudicial ou desonesto; usar o Serviço com o
          propósito de prejudicar a Umatch; fazer spam, solicitar dinheiro ou fraudar qualquer membro; personificar
          qualquer pessoa ou entidade, ou publicar imagens de outra pessoa sem a permissão dela; ameaçar, perseguir,
          intimidar, agredir, assediar, maltratar ou difamar qualquer pessoa; publicar qualquer Conteúdo que viole ou
          infrinja os direitos de outra pessoa, inclusive direitos de publicidade, privacidade, direitos autorais, marca
          comercial ou outra propriedade intelectual ou direito contratual; publicar qualquer Conteúdo de ódio,
          ameaçador, sexualmente explícito ou pornográfico; publicar qualquer Conteúdo que incite a violência, contenha
          nudez, violência gráfica ou gratuita; publicar qualquer Conteúdo que promova racismo, fanatismo, ódio ou danos
          físicos de qualquer natureza contra qualquer grupo ou indivíduo; solicitar senhas e informações de
          identificação pessoal de outros membros, para qualquer propósito, para fins comerciais ou ilegais, ou divulgar
          informações pessoais de outra pessoa sem a permissão dela; usar a conta de outro membro, compartilhar uma
          conta com outro membro ou manter mais de uma conta; criar outra conta se já tivermos cancelado a sua conta, a
          menos que você tenha a nossa permissão. A Umatch reserva-se o direito de investigar e/ou cancelar a sua conta,
          sem o reembolso de qualquer compra, se você violar este Contrato, utilizar o Serviço de forma inadequada ou se
          comportar de uma forma que a Umatch considere inadequada ou ilegal, incluindo ações ou comunicações que
          ocorram dentro ou fora do Serviço. Para garantir a segurança e integridade da comunidade, os próprios usuários
          podem denunciar perfis que apresentem má conduta, como assédio, mensagens ofensivas, perfis falsos, ausência 
          de uma pessoa real nas fotos, conteúdos inapropriados ou propagandas não autorizadas. Uma vez atingido um 
          limite significativo de denúncias, a conta poderá ser automaticamente banida, sem aviso prévio e sem direito 
          a reembolso.
        </p>

        <h2>8. Conteúdo de outros membros</h2>
        <p>
          Embora a Umatch reserve-se no direito de revisar e remover o Conteúdo que viola este Contrato, o referido
          Conteúdo é de exclusiva responsabilidade do membro que o publica, e a Umatch não pode garantir que todo o
          Conteúdo cumprirá com este Contrato. Se você se deparar com algum Conteúdo no Serviço que implique na violação
          deste Contrato, informe o problema diretamente pelo Serviço ou pelo nosso formulário de contato.
        </p>

        <h2>9. Isenção de responsabilidade</h2>
        <p>
          A UMATCH OFERECE O SERVIÇO “NO ESTADO EM QUE SE ENCONTRA”, “CONFORME DISPONÍVEL” E NA EXTENSÃO PERMITIDA PELA
          LEI APLICÁVEL, NÃO CONCEDE GARANTIAS DE QUALQUER TIPO, EXPRESSAS, IMPLÍCITAS, ESTATUTÁRIAS OU DE OUTRA FORMA
          COM RELAÇÃO AO SERVIÇO (INCLUINDO TODO O CONTEÚDO CONTIDO NO MESMO) INCLUSIVE, ENTRE OUTRAS, GARANTIAS
          IMPLÍCITAS DE QUALIDADE SATISFATÓRIA, COMERCIALIZAÇÃO, ADEQUAÇÃO A UM DETERMINADO FIM OU NÃO VIOLAÇÃO. A
          UMATCH NÃO DECLARA OU GARANTE QUE (A) O SERVIÇO SERÁ ININTERRUPTO, SEGURO OU LIVRE DE ERROS, (B) QUAISQUER
          DEFEITOS OU ERROS NO SERVIÇO SERÃO CORRIGIDOS, OU (C) QUE QUALQUER CONTEÚDO OU INFORMAÇÃO QUE VOCÊ OBTENHA NO
          OU ATRAVÉS DO SERVIÇO SERÃO PRECISAS. A UMATCH NÃO ASSUME NENHUMA RESPONSABILIDADE POR QUALQUER CONTEÚDO QUE
          VOCÊ, OUTROS MEMBROS OU TERCEIROS PUBLICAM, ENVIAM OU RECEBEM ATRAVÉS DO SERVIÇO. QUALQUER MATERIAL BAIXADO
          OU, DE OUTRA FORMA, OBTIDO ATRAVÉS DO USO DO SERVIÇO, É ACESSADO POR SUA PRÓPRIA CONTA E RISCO.
        </p>

        <h2>10. Serviços terceirizados</h2>
        <p>
          O Serviço pode conter anúncios e promoções oferecidas por terceiros, bem como links para outros sites ou
          recursos. A Umatch não é responsável pela disponibilidade (ou falta de disponibilidade) desses sites ou
          recursos externos. Se optar por interagir com terceiros disponibilizados através do nosso Serviço, os termos
          desse terceiro controlarão a relação deles com você. A Umatch não é responsável pelos termos ou ações de
          terceiros.
        </p>

        <h2>11. Limitação de responsabilidade</h2>
        <p>
          EM TODA A EXTENSÃO PERMITIDA PELA LEI APLICÁVEL, EM NENHUMA CIRCUNSTÂNCIA, A UMATCH, SUAS AFILIADAS,
          FUNCIONÁRIOS, LICENCIADORES OU PRESTADORES DE SERVIÇOS SERÃO RESPONSÁVEIS POR QUAISQUER DANOS INDIRETOS,
          CONSEQUENTES, EXEMPLARES, INCIDENTAIS, ESPECIAIS OU PUNITIVOS, INCLUSIVE, ENTRE OUTROS, PERDA DE LUCROS,
          INCORRIDA DIRETA OU INDIRETAMENTE, OU PERDA DE DADOS, USO, CLIENTELA E OUTRAS PERDAS INTANGÍVEIS, RESULTANTES
          DO SEGUINTE: (I) O SEU ACESSO, UTILIZAÇÃO OU INCAPACIDADE DE ACESSO OU UTILIZAÇÃO DOS SERVIÇOS, (II) A CONDUTA
          OU CONTEÚDO DE OUTROS MEMBROS OU TERCEIROS NA UTILIZAÇÃO DOS SERVIÇOS; OU (III) ACESSO, USO OU ALTERAÇÃO NÃO
          AUTORIZADA DO SEU CONTEÚDO, AINDA QUE A UMATCH TENHA SIDO AVISADA DA POSSIBILIDADE DOS REFERIDOS DANOS. EM
          NENHUMA CIRCUNSTÂNCIA, A RESPONSABILIDADE AGREGADA DA UMATCH EM RELAÇÃO A VOCÊ POR TODAS AS REIVINDICAÇÕES
          RELACIONADAS AO SERVIÇO EXCEDERÁ O VALOR PAGO PELO SERVIÇO, SE FOR O CASO, POR VOCÊ À UMATCH, OU 100 REAIS (O
          QUE FOR MAIOR), ENQUANTO VOCÊ TIVER UMA CONTA.
        </p>

        <h2>12. Indenização por Você</h2>
        <p>
          Você concorda, na extensão permitida pela lei aplicável, em indenizar, defender e isentar a Umatch, nossas
          afiliadas e seus respectivos executivos, diretores, agentes e funcionários de e contra quaisquer e todas as
          reclamações, demandas, reivindicações, danos, perdas, custos, responsabilidades e despesas, inclusive
          honorários advocatícios decorrentes, resultantes ou relacionados, de qualquer forma, com o seu acesso ou uso
          do Serviço, seu Conteúdo ou a sua violação deste Contrato.
        </p>

        <h2>13. Renúncia de ação coletiva e júri</h2>
        <p>
          Ao utilizar o Serviço sob qualquer forma, VOCÊ ABRE MÃO DO SEU DIREITO DE BUSCAR UM TRIBUNAL para resolver ou
          defender quaisquer reivindicações entre você e a Empresa (exceto para assuntos que possam ser levados ao
          juizado de pequenas causas). VOCÊ IGUALMENTE ABRE MÃO DO SEU DIREITO DE PARTICIPAR DE UMA AÇÃO COLETIVA OU
          OUTRO PROCESSO COLETIVO CONTRA A UMATCH.
        </p>

        <h2>14. Compras</h2>
        <p>
          Geralmente, de vez em quando, a Umatch pode oferecer produtos e serviços para compra através da App Store ou
          Google Play Store. Se você optar por fazer uma compra no aplicativo, será solicitado que você confirme sua
          compra com o provedor de pagamento aplicável e seu método de pagamento (Google Play Store ou App Store) (seu
          “Método de pagamento”) será cobrado pela compra no aplicativo de acordo com os preços exibidos para os
          serviços que você selecionou, bem como quaisquer vendas ou impostos semelhantes que possam ser cobrados em
          seus pagamentos, e você autoriza a Umatch ou a conta de terceiros, conforme aplicável, para cobrar de você. Se
          você comprar uma assinatura periódica auto-recorrente por meiode uma compra no aplicativo, seu Método de
          Pagamento continuará a ser cobrado pela assinatura até que você cancele. Após o período de compromisso de
          assinatura inicial e novamente após qualquer período de assinatura subsequente, sua assinatura continuará
          automaticamente por um período equivalente adicional, ao preço que você concordou ao assinar. As informações
          de pagamento do seu cartão serão armazenadas e posteriormente utilizadas para pagamentos automáticos com
          cartão de acordo com o Contrato. Objeções a um pagamento já feito devem ser direcionadas ao Suporte ao Cliente
          da App Store ou Google Play Store, de acordo com seu Método de pagamento. Você também pode contestar entrando
          em contato com seu banco ou provedor de pagamento, que pode fornecer mais informações sobre seus direitos, bem
          como os prazos aplicáveis. Você pode retirar incondicionalmente seu consentimento para pagamentos automáticos
          com cartão a qualquermomento acessando Configurações na conta de terceiros relevante, mas esteja ciente de que
          ainda é obrigado a pagar quaisquer valores pendentes.Se quiser alterar ou encerrar sua assinatura, você
          precisará fazer login em sua conta de terceiro e seguir as instruções para encerrar ou cancelar sua
          assinatura, mesmo se você tiver excluído sua conta com nós ou se você tiver excluído o aplicativo Umatch do
          seu dispositivo. Excluir sua conta na Umatch ou excluir o aplicativo Umatch do seu dispositivo não encerra ou
          cancela sua assinatura; A Umatch irá reter todos os fundos cobrados no seu Método de Pagamento até que você
          encerre ou cancele sua assinatura na contade terceiros. Se você encerrar ou cancelar sua assinatura, poderá
          usá-la até o final do prazo de assinatura então vigente, e sua assinatura não será renovada depois que o prazo
          atual expirar.De vez em quando, você pode comprar ou ganhar uma licença limitada, pessoal, intransferível, não
          sublicenciável e revogável parausar "itens virtuais", que podem incluir produtos virtuais ou "moedas" virtuais
          ou outras unidades que são intercambiáveis dentro do Serviço por produtos virtuais (coletivamente, "Itens
          Virtuais"). Qualquer saldo de Item Virtual mostrado em sua conta não constitui um saldo do mundo real ou
          reflete qualquer valor armazenado, mas constitui uma medida da extensão de sua licença. Os Itens Virtuais não
          incorrem em taxas por não uso, no entanto, a licença concedida a você nos Itens Virtuais será encerrada de
          acordo com os termos deste Contrato, quando a Umatch deixar de fornecer o Serviço ou sua conta for encerrada.
          A Umatch, a seu exclusivo critério, reserva-se o direito de cobrar taxas pelo direito de acessar ou usar os
          Itens Virtuais e pode distribuir os Itens Virtuais com ou sem custo. A Umatch pode gerenciar, regular,
          controlar, modificar ou eliminar itens virtuais a qualquer momento. A Umatch não terá nenhuma responsabilidade
          para com você ou qualquer terceiro caso a Umatch exerça tais direitos. Os itens virtuais só podem ser
          resgatados por meio do Serviço. TODAS AS COMPRAS E RESGATES DE ITENS VIRTUAIS REALIZADAS ATRAVÉS DOSERVIÇO SÃO
          FINAIS E NÃO REEMBOLSÁVEIS. O fornecimento de Itens Virtuais para uso no Serviço é um serviço que começa
          imediatamente após a aceitação da compra de tais Itens Virtuais. VOCÊ RECONHECE QUE A UMATCH NÃO É OBRIGADA A
          FORNECER UMREEMBOLSO EM RELAÇÃO A ITENS VIRTUAIS POR QUALQUER MOTIVO, E QUE NÃO RECEBERÁ DINHEIRO OU OUTRA
          COMPENSAÇÃOPOR ITENS VIRTUAIS NÃO UTILIZADOS QUANDO UMA CONTA ESTIVER FECHADA, INDEPENDENTE DE TAL FECHAMENTO
          TER SIDO VOLUNTÁRIO OU INVOLUNTÁRIO.As compras de Itens Virtuais são FINAIS E NÃO REEMBOLSÁVEIS. Se você fez
          uma compra usando seu ID da Apple, os reembolsos são feitos pela Apple, não pela Umatch. Para solicitar um
          reembolso, acesse a App Store, clique no seu ID Apple, selecione "Histórico de compras", localize a transação
          e clique em "Relatar problema". Vocêtambém pode enviar uma solicitação em https://getsupport.apple.com. Se
          você fez uma compra usando sua conta da Google Play Store e deseja obter um reembolso: entre em contato com o
          suporte ao cliente com o número do seu pedido para a Google Play Store (você pode encontrar o número do pedido
          no e-mail de confirmação do pedido ou fazendo login na Carteira virtual do Google).Você não pode cancelar um
          pedido de entrega de conteúdo digital que não seja entregue em um meio físico se o processamento do pedido
          tiver começado com o seu consentimento prévio explícito e reconhecimento de que você perderá o seu direito de
          cancelamento.Isso se aplica, por exemplo, a compras de itens virtuais. Isso significa que essas compras são
          FINAIS E NÃO REEMBOLSÁVEIS.Nossos preços variam de acordo com uma série de fatores. Frequentemente oferecemos
          tarifas promocionais - que podem variar de acordo com a região, duração da assinatura, tamanho do pacote e
          muito mais. Também testamos regularmente novos recursos.
        </p>

        <h2>15. Contrato integral</h2>
        <p>
          Este Contrato, juntamente com a Política de Privacidade (disponível em https://www.umatch.com.br/privacy) e os
          Procedimentos Arbitrais (se aplicáveis a você) e quaisquer termos divulgados e acordados por você, se adquirir
          recursos, produtos ou serviços adicionais que oferecemos no Serviço, constitui o contrato integral entre você
          e a Umatch, sobre a utilização do Serviço. Se qualquer disposição deste Contrato for considerada inválida, as
          demais disposições deste Contrato permanecerão em pleno vigor e efeito. A incapacidade da Empresa em exercer
          ou exigir qualquer direito ou disposição deste Contrato não constituirá uma renúncia a este direito ou
          disposição. Você concorda que a sua conta da Umatch é intransferível e todos os seus direitos sob a sua conta
          e seu conteúdo terminam após a sua morte. Nenhuma agência, parceria, joint venture, fiduciária ou outra
          relação ou contratação especial é criada como resultado deste Contrato, e você não poderá, de maneira alguma,
          fazer quaisquer declarações ou vincular a Umatch.
        </p>
      </Content>
      <Footer />
    </Container>
  );
};
